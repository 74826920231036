<template>
  <svg
    title="trailer-icon"
    width="90"
    height="60"
    viewBox="0 0 90 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9018 41.7037C18.8593 41.7037 14.7542 45.8091 14.7542 50.8519C14.7542 55.8947 18.8593 60 23.9018 60C28.9443 60 33.0494 55.8947 33.0494 50.8519C33.0494 45.8091 28.948 41.7037 23.9018 41.7037ZM23.9018 58.7625C19.541 58.7625 15.9917 55.2129 15.9917 50.8519C15.9917 46.4908 19.541 42.9412 23.9018 42.9412C28.2626 42.9412 31.8119 46.4908 31.8119 50.8519C31.8119 55.2166 28.2626 58.7625 23.9018 58.7625Z"
      fill="currentColor"
    />
    <path
      d="M43.5741 41.7037C38.5316 41.7037 34.4265 45.8091 34.4265 50.8519C34.4265 55.8947 38.5316 60 43.5741 60C48.6165 60 52.7217 55.8947 52.7217 50.8519C52.7217 45.8091 48.6202 41.7037 43.5741 41.7037ZM43.5741 58.7625C39.2133 58.7625 35.6639 55.2129 35.6639 50.8519C35.6639 46.4908 39.2133 42.9412 43.5741 42.9412C47.9348 42.9412 51.4842 46.4908 51.4842 50.8519C51.4842 55.2166 47.9348 58.7625 43.5741 58.7625Z"
      fill="currentColor"
    />
    <path
      d="M63.2464 41.7037C58.2039 41.7037 54.0988 45.8091 54.0988 50.8519C54.0988 55.8947 58.2039 60 63.2464 60C68.2888 60 72.394 55.8947 72.394 50.8519C72.394 45.8091 68.2925 41.7037 63.2464 41.7037ZM63.2464 58.7625C58.8856 58.7625 55.3362 55.2129 55.3362 50.8519C55.3362 46.4908 58.8856 42.9412 63.2464 42.9412C67.6071 42.9412 71.1565 46.4908 71.1565 50.8519C71.1565 55.2166 67.6071 58.7625 63.2464 58.7625Z"
      fill="currentColor"
    />
    <path
      d="M23.9005 46.2913C21.3848 46.2913 19.3397 48.3366 19.3397 50.8525C19.3397 53.3683 21.3848 55.4135 23.9005 55.4135C26.4162 55.4135 28.4613 53.3683 28.4613 50.8525C28.4613 48.3366 26.4162 46.2913 23.9005 46.2913ZM23.9005 54.1723C22.0702 54.1723 20.5808 52.6828 20.5808 50.8525C20.5808 49.0221 22.0702 47.5326 23.9005 47.5326C25.7308 47.5326 27.2202 49.0221 27.2202 50.8525C27.2239 52.6828 25.7308 54.1723 23.9005 54.1723Z"
      fill="#FC4236"
    />
    <path
      d="M43.5728 46.2913C41.0571 46.2913 39.012 48.3366 39.012 50.8525C39.012 53.3683 41.0571 55.4135 43.5728 55.4135C46.0885 55.4135 48.1336 53.3683 48.1336 50.8525C48.1336 48.3366 46.0885 46.2913 43.5728 46.2913ZM43.5728 54.1723C41.7425 54.1723 40.2531 52.6828 40.2531 50.8525C40.2531 49.0221 41.7425 47.5326 43.5728 47.5326C45.4031 47.5326 46.8925 49.0221 46.8925 50.8525C46.8962 52.6828 45.4031 54.1723 43.5728 54.1723Z"
      fill="#FC4236"
    />
    <path
      d="M63.245 46.2913C60.7294 46.2913 58.6842 48.3366 58.6842 50.8525C58.6842 53.3683 60.7294 55.4135 63.245 55.4135C65.7607 55.4135 67.8059 53.3683 67.8059 50.8525C67.8059 48.3366 65.7607 46.2913 63.245 46.2913ZM63.245 54.1723C61.4148 54.1723 59.9254 52.6828 59.9254 50.8525C59.9254 49.0221 61.4148 47.5326 63.245 47.5326C65.0753 47.5326 66.5647 49.0221 66.5647 50.8525C66.5684 52.6828 65.0753 54.1723 63.245 54.1723Z"
      fill="#FC4236"
    />
    <path
      d="M22.6901 40.4421C23.1033 40.4421 23.4401 40.166 23.4401 39.8274C23.4401 39.4887 23.1033 39.2126 22.6901 39.2126H1.50011V34.4012H87.531V39.2126H66.341C65.9278 39.2126 65.591 39.4887 65.591 39.8274C65.591 40.166 65.9278 40.4421 66.341 40.4421H88.281C88.6942 40.4421 89.031 40.166 89.031 39.8274V0.614739C89.031 0.276065 88.6942 0 88.281 0H0.75004C0.33684 0 0 0.276065 0 0.614739V39.8274C0 40.166 0.33684 40.4421 0.75004 40.4421H7.67115V42.6642C7.67115 43.0029 8.00799 43.279 8.42119 43.279C8.83439 43.279 9.17123 43.0029 9.17123 42.6642V40.4421H22.6901ZM87.531 1.22952V33.168H1.50011V1.22952H87.531Z"
      fill="currentColor"
    />
    <path
      d="M88.2152 47.6231H77.8046L71.8503 42.332C71.5947 42.106 71.2019 42.1282 70.9759 42.3839C70.7499 42.6396 70.7721 43.0323 71.0278 43.2583L77.1599 48.705C77.2748 48.8051 77.4193 48.8606 77.5712 48.8606H88.2189C88.5598 48.8606 88.8377 48.5827 88.8377 48.2419C88.8377 47.901 88.5598 47.6231 88.2152 47.6231Z"
      fill="currentColor"
    />
  </svg>
</template>
